<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 标题 -->
					<el-form-item label="标题名称" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" placeholder="请填写标题" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 简介 -->
					<el-form-item label="简介" prop="synopsis">
						<div class="aaaa">
							<el-input v-model="form.synopsis" placeholder="请填写简介" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 编辑器 -->
					<el-form-item prop="copyreader">
						<div class="subject">
							<span>*</span>
							主体内容
						</div>
						<div style="border: 1px solid #ccc; width:100%">
							<Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef"
								:defaultConfig="toolbarConfig" :mode="mode" />

							<Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml"
								:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
		</el-card>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted,
		reactive
	} from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import {
		ElMessage
	} from "element-plus";
	import {
		XJperson
	} from '../../utils/api'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		useStore
	} from "vuex";
	import qs from 'qs'
	export default {
		name: "dxgladd",
		components: {
			Editor,
			Toolbar,
		},
		setup() {
			// 路由跳转
			const router = useRouter()
			const route = useRoute();
			// 按钮加载
			const ConfirmLoading = ref(false)
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 提交 按钮
			const onSubmit = () => {
				// ConfirmLoading.value=true


				console.log(valueHtml.value)
				if (valueHtml.value == '<p><br></p>') {
					form.copyreader = ''
				} else {
					form.copyreader = valueHtml.value
				}
				ruleFormRef.value.validate((valid) => {
					if (valid == true) {
						ConfirmLoading.value = true
						let data = {
							modelId: sessionStorage.getItem('modesids'),
							title: form.name,
							content: form.copyreader,
							introduce: form.synopsis
						}
						XJperson(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList
									
									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'xjmanage',
										params: {
											UserId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)

							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					console.log(form)
				})
			}
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('')

			// 模拟 ajax 异步获取内容
			onMounted(() => {})

			const toolbarConfig = {
				toolbarKeys: [
					'bold', // 加粗
					'italic', // 斜体
					'through', // 删除线
					'underline', // 下划线
					'bulletedList', // 无序列表
					'numberedList', // 有序列表
					'color', // 文字颜色
					'fontSize', // 字体大小
					'lineHeight', // 行高
					'uploadImage', // 上传图片
					'delIndent', // 缩进
					'indent', // 增进
					'deleteImage', //删除图片
					'justifyCenter', // 居中对齐
					'justifyJustify', // 两端对齐
					'justifyLeft', // 左对齐
					'justifyRight', // 右对齐
					'undo', // 撤销
					'redo', // 重做
					'clearStyle', // 清除格式
				],
				excludeKeys: [
					'insertImage', // 网络图片
					'bgColor', // 背景色
					'blockquote', // 引用
					'codeBlock', // 代码段
					'emotion', // 表情
					'fontFamily', // 字体
					'headerSelect', // 标题 
					'fullScreen', // 全屏
				],
			}
			const editorConfig = {
				MENU_CONF: {}
			}
			editorConfig.MENU_CONF['uploadImage'] = {
				// 接口
				server: '/admin/model/character/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				// 限制图片大小
				maxFileSize: 1 * 1024 * 1024, // 1M
				//上传字段 
				fieldName: 'imageFile',
				// 最多可上传几个文件，默认为 100
				maxNumberOfFiles: 1,
				// 上传前的回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 压缩图片
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 1048576) {
						return true
					} else if (isLt2M > 1048576) {
						ElMessage({
							message: '图片大小不能超过1MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError() {
					ElMessage({
						message: '图片大小不能超过1MB,请重新上传',
						type: "warning",
						center: true,
					});
				},

			}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})

			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
				// console.log(editor)
			}
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				name: "",
				copyreader: "",
				synopsis: ''
			})
			// 验证表单提示
			const rules = {
				copyreader: [{
					required: true,
					message: "请填写内容",
					trigger: "change"
				}, ],
				name: [{
					required: true,
					message: "请填写标题",
					trigger: "blur"
				}, ],
			}
			return {
				// 按钮加载
				ConfirmLoading,
				// 编辑器
				onSubmit,
				editorRef,
				valueHtml,
				mode: 'default',
				toolbarConfig,
				editorConfig,
				handleCreated,
				// 标题验证
				ruleFormRef,
				form,
				rules
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 150px);
		overflow: auto;
	}

	.subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		span {
			color: var(--el-color-danger);
		}
	}

	.el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	.el-form-item__content {
		align-items: flex-end;
	}

	.referrals {
		color: #cccccc;
		margin-left: 5px;
	}

	.styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	.styC .el-upload--picture-card {
		display: none;
	}

	.el-form-item {
		align-items: center;
	}

	.aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 80px !important;
		font-weight: bold;
	}
</style>